import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import arrow from '../assets/img/Arrow.svg';
import { People } from "../assets/img/People"
import { CiSearch } from "react-icons/ci";
import Accordion from 'react-bootstrap/Accordion';
import { userCards } from '../assets/data';
import avatar from "../assets/img/Avatar.svg"
import verifyimg from "../assets/img/verify.svg"
import l1img from "../assets/img/l3.png"
import PlearFilters from '../components/modal/PlearFilters';
import { AppContext } from '../context/CreateContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios"
import { BAE_URL_API, IMAGE_URL } from '../Config';


const PlearSearch = () => {

    const matches = useMediaQuery('(max-width:600px)');
    const { setOpen } = useContext(AppContext)
    const [profilesInfo, setProfilesInfo] = useState([]);
    const [searching, setSearching] = useState("")
    const [years, setYears] = useState("")
    const [offers,setOffers] = useState([])

    useEffect(() => {
        getProfileData()
    }, [])
    const getProfileData = async () => {
        try {
            const res = await axios.get(`${BAE_URL_API}/getProfileData`);
            setProfilesInfo(res?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const Filtering = profilesInfo.filter((item) => {
        return item.year.trim().includes(years.trim())
    })
    const filterprofile = Filtering.filter((item) => {
        return item.name.toLowerCase().trim().includes(searching.toLowerCase().trim())
    })

 
console.log(offers)
    return (
        <>
            <PlearFilters />
            <section className="playerprofile_section">
                <Container>
                    <div className="flex justify-between mt-4">
                        <Link to="/"><img src={arrow} alt="" /></Link>
                        <div className='flex gap-2 text-xl'> <People color={"black"} /> Search player</div>
                        <div className='mydiv'></div>
                    </div>

                    <section>
                        {/* <div className='flex  items-center shoadows p-2 justify-between rounded-full w-full relative'>
                            <button onClick={() => setActiveBtn("PR")} className={`w-5/12 searchpro flex items-center justify-center gap-2   rounded-full p-2  `}>
                                <Award color={"black"} /> Search program
                            </button>
                            <button onClick={() => setActiveBtn("PM")} className={`w-5/12 activeBtnin text-white searchplay flex items-center justify-center gap-2   rounded-full p-2 `}>
                                <People color={"white"} /> Search player
                            </button>
                        </div> */}

                        <div className='flex items-center mt-3 gap-2'>
                            <div className='flex items-center w-full bg-[#F8FAFC] border rounded-full pl-3 pr-1 border-[#E9E9E9] gap-1'>
                                <CiSearch className='text-[#818181] ' />
                                <input type="text" placeholder='Search' className='w-full outline-none border-none bg-[#F8FAFC] rounded-full p-2' onChange={(e) => setSearching(e.target.value)} />
                            </div>
                            {/* <div className='p-3 flex items-center justify-center rounded-full w-[45px] h-[45px] bg-[#F8FAFC] cursor-pointer border border-[#E9E9E9]' onClick={() => setOpen(true)}>
                                <i className="bi bi-sliders"></i></div> */}
                        </div>
                        <div className='mt-3 flex flex-wrap items-center md:justify-normal justify-center gap-2'>
                            <select className='border border-[#DBDBDB] shadow-none p-2 px-3 rounded-full form-select w-[130px] outline-none cursor-pointer' >
                                <option value="Position">Position</option>
                                <option value="Position 2">Position 2</option>
                                <option value="Position 3">Position 3</option>
                            </select>
                            {/* <select className='border border-[#DBDBDB] shadow-none p-2 px-3 rounded-full form-select w-[130px] outline-none cursor-pointer'>
                                <option value="Class">Class</option>
                                <option value="Class 2">Class 2</option>
                                <option value="Class 3">Class 3</option>
                            </select>
                            <select className='border border-[#DBDBDB] shadow-none p-2 px-3 rounded-full form-select w-[150px] outline-none cursor-pointer'>
                                <option value="Height">Height</option>
                                <option value="Grater then 5">Grater then 5</option>
                                <option value="Grater then 6">Grater then 6</option>
                                <option value="Grater then 7">Grater then 7</option>
                            </select> */}
                            <select value={years} className='border border-[#DBDBDB] shadow-none p-2 px-3 rounded-full form-select w-[150px] outline-none cursor-pointer'
                                onChange={(e) => setYears(e.target.value)}>
                                <option value="">clear</option>
                                {profilesInfo.map((item, i) => (
                                    <option value={`${item.year}`} key={i}>{item.year}</option>
                                ))}
                            </select>
                        </div>
                           <div className='w-full overflow-auto'>
                           <div className='mt-3  min-w-[500px]'>
                                {filterprofile.map((item, i) => (
                                    <Accordion className='acourdionshadow outline-none my-3' key={i}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <div className='flex items-center justify-between w-full pr-1'>
                                                    <Link to={`/ViewProfile/${item._id}`}>
                                                        <div className='flex gap-3 items-center'>
                                                            <img src={`${IMAGE_URL}/${item.image}`} alt="icons" className='rounded-full w-[50px] h-[50px] object-cover' />
                                                            <div>
                                                                <div className='flex gap-2 font-bold items-center'>
                                                                    {item.name} <img src={verifyimg} alt="icons" />
                                                                    {/* <div className='flex items-center gap-3 ml-3'>
                                                                    <p className='text-red-500 font-[400]'>Follow</p>
                                                                    <i className="bi bi-star text-[15px]"></i>
                                                                </div> */}
                                                                </div>
                                                                <p className='text-[14px] text-[#696969]'>{item.university}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <p>{item.division}</p>
                                                    <div>F  |  {item.height}  |  {item.weight} Ibs  |  {item.year}</div>
                                                    <div className='border rounded-full border-[#EFF0F2] w-[40px] h-[40px] flex items-center justify-center'>
                                                        <img src={l1img} alt="icons" className='w-[20px]' /></div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='flex items-center justify-between w-full gap-2'>
                                                    {item.Offers.map((ofer, i) => (
                                                       <div key={i}>
                                                         {ofer.verify ? <div className='text-center flex flex-col gap-2 h-[100px] justify-between'>
                                                            <img src={`${IMAGE_URL}/${item.email}/${ofer.img}`} alt="" className='m-auto w-[60px] h-[60px] object-cover rounded-full' />
                                                    
                                                            <p className='font-bold'>{ofer.uniname}</p>
                                                        </div> :null}
                                                       </div>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                            </div>
                           </div>
                    </section>
                </Container>

            </section>
        </>
    )
}

export default PlearSearch
