import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import arrow from '../assets/img/Arrow.svg';
import Fb from '../assets/img/fb.svg';
import Insta from '../assets/img/insta.svg';
import Yt from '../assets/img/yt.svg';
import X from '../assets/img/X.svg';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../context/CreateContext';
import { BAE_URL_API, IMAGE_URL } from '../Config';
import profilimg from "../assets/img/dummi.png"
import nameplat from "../assets/img/plt.svg"
import varifyingimg from "../assets/img/verify.svg"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios"
import ViewDetails from '../components/details/ViewDetails';
import AproveingOffers from '../components/AprovingOffers/AproveingOffers';


const ViewProfile = () => {

    const { isAdmin, preving } = useContext(AppContext)
    const [profileDAta, setProfileData] = useState([])
    const [seasons, setSeasons] = useState([])
    const [getvideo, setGetvideo] = useState([])
    const [getImages, setGetImages] = useState([])
    const [selectSeason, setSelectSeason] = useState([])
    const [getoffers, setGetOffers] = useState([])
    const [profilUserEmail, setProfileUserEmail] = useState("")
    const [proving, setAproving] = useState({ edit: false, name: "", date: "", varify: false, Id: "", logo: "", img: "", email: "" })
    const { id } = useParams();
    const [countPosts, setCountPosts] = useState({ imgCnt: 0, vdoCnt: 0 });
     
    useEffect(() => {
        setCountPosts((prev) => ({ ...prev, vdoCnt: 0, imgCnt: 0 }));
        getUserProfile()
    }, [preving])

    useEffect(() => {
        setSelectSeason(seasons[0])
    }, [seasons])


    const getUserProfile = async () => {
        try {
            const data = { Id: id }
            const respo = await axios.post(`${BAE_URL_API}/findOneProfile`, data)
            setProfileData(respo?.data)
            setGetImages(respo?.data?.Images)
            setGetvideo(respo?.data?.Videos)
            setGetOffers(respo?.data?.Offers)
            setSeasons(respo?.data?.Seasons)
            setProfileUserEmail(respo?.data?.email)
            setCountPosts((prev) => ({
                ...prev,
                imgCnt: respo?.data?.Images?.length,
                vdoCnt: respo?.data?.Videos?.length,
            }));
        } catch (error) {
            console.log(error)
        }
    }

    const scrollFunc = () => {
        setTimeout(() => {
            document.getElementById("scrolling").scrollIntoView({ behavior: "smooth" });
            document.getElementById("scrolling2").scrollIntoView({ behavior: "smooth" });
            document.getElementById("scroling3").scrollIntoView({ behavior: "smooth" });
        }, 400);
    };

    const SortFunc = (val) => {
        for (let i = 0; i <= seasons?.length; i++) {
            const data = seasons[i]
            if (data.season === val) {
                setSelectSeason(data)
                break
            }
        }
    }


    return (
        <section className="playerprofile_section">
            <AproveingOffers proving={proving} />
            <Container>
                <div className="playerprofile_title">
                    <Link to="/"><img src={arrow} alt="" /></Link>
                    <p className='capitalize'>{profileDAta.name}</p>
                    <div className='mydiv'></div>
                </div>
                <div className="playerpro_inner_main">
                    <div className="playerpro_inner_for_shadow">
                        <div className="playerprofile_profile_card_main_div">
                            <div className="playerprofile_profile_card_body">
                                <div className="playerprofile_profile_card_inner">
                                    <div className='profile_content1'>
                                        <div className="playerprofile_profile_img">
                                            <div className="playerprofile_profile_inner1">
                                                {profileDAta.image ? <img src={`${IMAGE_URL}/${profileDAta.image}`} alt="" /> :
                                                    <img src={profilimg} alt="image" />}
                                                <span></span>
                                            </div>
                                            <img src={nameplat} alt="image" className='imageName' />
                                            <h2 className='backimg '>
                                                {profileDAta.role || "role"}</h2>
                                        </div>
                                        <div className="playerprofile_profile_card_title">
                                            <div className='mils my-1'> <h1 className='m-0 text-2xl font-bold capitalize'>{profileDAta.name || "name"}</h1> <img src={varifyingimg} alt="iconn" /></div>
                                            <div className='mils capitalize'>
                                                {profileDAta.university || "University"} </div>
                                            <br />
                                            <div className="playerprofile_social_icons_main">
                                                <div className='p-[15px]'>
                                                    <img src={Fb} alt="" /></div>
                                                <div><img src={Insta} alt="" /></div>
                                                <div><img src={Yt} alt="" /></div>
                                                <div> <img src={X} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='profile_content2'>
                                        <div>
                                            <div className="playerprofile_info_div">
                                                <div>
                                                    <p>Height</p>
                                                    <h6>{profileDAta.height || 0}</h6>
                                                </div>
                                                <div>
                                                    <p>Weight</p>
                                                    <h6>{profileDAta.weight || 0} lbs</h6>
                                                </div>
                                                <div style={{ borderRight: "none" }}>
                                                    <p>Year</p>
                                                    <h6>{profileDAta.year || 0}</h6>
                                                </div>
                                                <div>
                                                    <h6>{countPosts.imgCnt + countPosts.vdoCnt || 0}</h6>
                                                    <p>Posts</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* profile tabs ======================================================= */}

                    <section className="profiletab_section">
                        <Container>
                            <div className="playerprofile_profile_tabs_main_div">

                                <Tabs
                                    onClick={scrollFunc}
                                    defaultActiveKey="Videos"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3" >
                                    <Tab eventKey="Videos" title="Videos">
                                        <ViewDetails profileDAta={profileDAta} selectSeason={selectSeason} seasons={seasons} SortFunc={SortFunc} />
                                        <div className="video_tab_last_videos_main mt-3">
                                            <div className='w-full boxphoto' id='scrolling'>
                                                {getvideo?.map((item, i) => (
                                                    <div className="w-full h-fit m-1 boxover" key={i}>
                                                        <video loop preload="auto" className='rounded w-full h-[200px] mb-1 object-cover' controls playsInline={true}>
                                                            <source src={`${IMAGE_URL}/${item}`} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Photos" title="Photos">
                                        <ViewDetails profileDAta={profileDAta} selectSeason={selectSeason}
                                            seasons={seasons}
                                            SortFunc={SortFunc} />
                                        <div className='w-full mt-3 boxphoto' id='scrolling2'>
                                            {getImages?.map((item, i) => (
                                                <div className="w-fit boxover" id='scrolling' key={i}>
                                                    <img src={`${IMAGE_URL}/${item}`} alt="images" className='rounded w-[300px] h-[200px] mb-1 object-cover' />
                                                </div>
                                            ))}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Offers" title="Offers">
                                        <ViewDetails profileDAta={profileDAta} selectSeason={selectSeason}
                                            seasons={seasons}
                                            SortFunc={SortFunc} />
                                        {profileDAta && profileDAta.Article && profileDAta.Article.map((item, index) => (
                                            <div className='px-4 py-2  bg-[#eff0fa] my-3 rounded-full' key={index}>
                                                <Link to={`/ShowArticle/${profileDAta._id}/${item._id}`} className='capitalize text-xl underline'>{index + 1} Article : {item.title}</Link>
                                            </div>
                                        ))}

                                        <div className="offers_tab_last_cards_main_div mt-3" id='scroling3'>
                                            <Row>
                                                {getoffers?.map((item, i) => (
                                                    item.verify ? (
                                                        <Col lg="4" md="6" sm="12" key={i}>
                                                            <div className="offers_tab_last_card_body">
                                                                <div className='offers_tab_last_inner1'>
                                                                    <img src={`${IMAGE_URL}/${profilUserEmail}/${item.img}`} alt="User" className='w-[50px] h-[50px] rounded-full object-cover' />
                                                                    <div className="offers_tab_last_inner1_text">
                                                                        <h4 className='capitalize'>{item.uniname}</h4>
                                                                        <p>{item.date}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="offers_tab_last_card_btn">
                                                                    {isAdmin && (
                                                                        <button className='py-1 px-4 mb-1 rounded-full bg-[#e9ecff]' onClick={() => setAproving((prev) => ({ ...prev, name: item.uniname, date: item.date, varify: item.varify, Id: item._id, logo: item.logo, img: item.img, edit: true, email: profileDAta.email }))}>
                                                                            Edit
                                                                        </button>
                                                                    )}
                                                                    <button type='button' className={item.verify ? 'bg-green-200' : 'bg-red-200'}>
                                                                        {item.verify ? 'Offered' : 'Not Verified'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ) : isAdmin && <Col lg="4" md="6" sm="12" key={i}>
                                                        <div className="offers_tab_last_card_body">
                                                            <div className='offers_tab_last_inner1'>
                                                                <img src={`${IMAGE_URL}/${profilUserEmail}/${item.img}`} alt="User" className='w-[50px] h-[50px] rounded-full object-cover' />
                                                                <div className="offers_tab_last_inner1_text">
                                                                    <h4 className='capitalize'>{item.uniname}</h4>
                                                                    <p>{item.date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="offers_tab_last_card_btn">
                                                                {isAdmin && (
                                                                    <button className='py-1 px-4 mb-1 rounded-full bg-[#e9ecff]' onClick={() => setAproving((prev) => ({ ...prev, name: item.uniname, date: item.date, varify: item.varify, Id: item._id, logo: item.logo, img: item.img, edit: true, email: profileDAta.email }))}>
                                                                        Edit
                                                                    </button>
                                                                )}
                                                                <button type='button' className={item.verify ? 'bg-green-200' : 'bg-red-200'}>
                                                                    {item.verify ? 'Offered' : 'Not Verified'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}

                                            </Row>
                                        </div>


                                    </Tab>
                                </Tabs>
                            </div>
                        </Container>
                    </section>
                    {/* profile tabs======================================================= */}
                </div>
            </Container>
        </section>
    )
}

export default ViewProfile
