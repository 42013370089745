export const People = (c)=>{
 
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill={`${c.color}`} xmlns="http://www.w3.org/2000/svg">
<path d="M15.8388 7.29875C15.7775 7.29 15.7163 7.29 15.655 7.29875C14.2988 7.255 13.2225 6.14375 13.2225 4.77875C13.2225 3.3875 14.3513 2.25 15.7513 2.25C17.1425 2.25 18.28 3.37875 18.28 4.77875C18.2713 6.14375 17.195 7.255 15.8388 7.29875Z" fill={`${c.color}`}/>
<path d="M18.6913 13.3626C17.7113 14.0189 16.3375 14.2639 15.0688 14.0976C15.4013 13.3801 15.5763 12.5839 15.585 11.7439C15.585 10.8689 15.3925 10.0376 15.025 9.31136C16.32 9.13636 17.6938 9.38136 18.6825 10.0376C20.065 10.9476 20.065 12.4439 18.6913 13.3626Z" fill={`${c.color}`}/>
<path d="M6.13501 7.29875C6.19626 7.29 6.25751 7.29 6.31876 7.29875C7.67501 7.255 8.75127 6.14375 8.75127 4.77875C8.75127 3.37875 7.62251 2.25 6.22251 2.25C4.83126 2.25 3.70251 3.37875 3.70251 4.77875C3.70251 6.14375 4.77876 7.255 6.13501 7.29875Z" fill={`${c.color}`}/>
<path d="M6.23123 11.7438C6.23123 12.5925 6.41498 13.3975 6.74748 14.1238C5.51373 14.255 4.22748 13.9925 3.28248 13.3713C1.89998 12.4525 1.89998 10.9563 3.28248 10.0375C4.21873 9.40753 5.53998 9.15378 6.78248 9.29378C6.42373 10.0288 6.23123 10.86 6.23123 11.7438Z" fill={`${c.color}`}/>
<path d="M11.105 14.3862C11.035 14.3775 10.9562 14.3775 10.8775 14.3862C9.26745 14.3337 7.9812 13.0125 7.9812 11.385C7.98995 9.7225 9.3287 8.375 11 8.375C12.6625 8.375 14.01 9.7225 14.01 11.385C14.0012 13.0125 12.7237 14.3337 11.105 14.3862Z" fill={`${c.color}`}/>
<path d="M8.2612 16.1976C6.93995 17.0813 6.93995 18.5338 8.2612 19.4088C9.7662 20.4151 12.2337 20.4151 13.7387 19.4088C15.06 18.5251 15.06 17.0726 13.7387 16.1976C12.2425 15.1913 9.77495 15.1913 8.2612 16.1976Z" fill={AbortController}/>
</svg>
    )
}
